<template>
  <div class="content" id="overviewPage">
    <div class="container-fluid">
      <div class="row m-0">
        <div class="col-xs-12 col-md-6 text-left p-1">
          Dernier refresh à <b class="">{{ lastRefreshedAtComputed }}</b>
        </div>
        <div class="col-xs-12 col-md-6 text-right pr-0">
          <button class="btn btn-primary btn-simple pr-0" @click="getStats()">
            <i class="fa fa-refresh"></i> Rafraichir toutes les stats
          </button>
          <date-range-picker
            v-if="false"
            class="form-group vgt-date-range pl-2"
            :placeholder="$t('common.field.start')"
            @update="getStats()"
            :locale-data="datePicker.locale"
            v-model="dateRange"
            :opens="'left'"
            :auto-apply="true"
          >
          </date-range-picker>
        </div>
      </div>
      <hr />

      <div class="row">
        <div
          v-for="field in countByTable"
          class="col-xl-3 col-md-6 counter-widget"
          :key="field.key"
        >
          <div class="card shadow">
            <div class="card-body">
              <p class="card-category">{{ cardsTitles[field.key] }}</p>
              <h1 class="card-title text-secondary text-right">
                <b>{{ field.value }}</b>
              </h1>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-md-6 counter-widget"></div>
        <div class="col-xl-6 col-md-6 counter-widget"></div>
        <div class="col-xl-6 col-md-6 counter-widget"></div>

        <div class="col-xl-6 col-md-6 counter-widget"></div>
      </div>
      <div class="row">
        <div class="col-xl-3 col-md-6 counter-widget" v-if="false"></div>
        <div class="col-xl-3 col-md-6 counter-widget" v-if="false"></div>
        <div class="col-xl-3 col-md-6 counter-widget"></div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { rolesMixin } from 'vue-aw-components';
import DateRangePicker from 'vue2-daterange-picker';
import { startCase } from 'lodash';
// import qs from 'qs';

export default {
  mixins: [rolesMixin],
  components: {
    DateRangePicker,
  },
  data() {
    return {
      generalStats: {},
      countByTable: {},
      dateRange: {
        startDate: moment()
          .subtract(1, 'year')
          .format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      },
      lastRefreshedAt: null,
      graphNeedsRefresh: false,
      tableNeedsRefresh: false,
      elasticTableNeedsRefresh: false,
      currentMonth: moment().format('MM-YYYY'),
      currentYear: moment().format('YYYY'),
      datePicker: {
        locale: {
          direction: 'ltr', // direction of text
          format: 'DD-MM-YYYY', // fomart of the dates displayed
          separator: ' - ', // separator between the two ranges
          applyLabel: 'Appliquer',
          cancelLabel: 'Annuler',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          daysOfWeek: moment.weekdaysMin(), // array of days - see moment documenations for details
          monthNames: moment.monthsShort(), // array of month names - see moment documenations for details
          firstDay: 1, // ISO first day of week - see moment documenations for details
        },
      },

      lineChart: {
        data: {
          labels: [],
          series: [],
        },
        options: {
          low: 0,
          high: 500,
          showArea: false,
          height: '245px',
          axisX: {
            showGrid: false,
          },
          lineSmooth: true,
          showLine: true,
          showPoint: true,
          fullWidth: true,
          chartPadding: {
            right: 50,
          },
        },
        responsiveOptions: [
          [
            'screen and (max-width: 640px)',
            {
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      cardsTitles: {
        program: 'Nombre de programmes',
        module: 'Nombre de modules',
        chapter: 'Nombre de chapitres',
        workshop: "Nombre d'ateliers",
        goal: "Nombre d'Objectifs",
        user: "Nombre d'utilisateurs",
        quiz: 'Nombre de questionnaires',
      },
    };
  },
  mounted() {
    this.getStats();
    try {
      const savedStats = JSON.parse(localStorage.getItem('overviewPage_generalStats'));
      if (savedStats && savedStats.columns && savedStats.data) {
        // this.statsTable = savedStats;
        // this.graphNeedsRefresh = true;
      }
    } catch (e) {
      console.warn(e);
    }
  },

  beforeDestroy() {},

  computed: {
    lastRefreshedAtComputed() {
      return moment(this.lastRefreshedAt).format('dddd - DD MMM hh:mm:ss');
    },
  },
  methods: {
    startCase,
    formatDate(date) {
      if (!date) {
        return;
      }
      return moment(date).format('dddd - DD MMM');
    },

    formatNumber(number) {
      if (!number) {
        return;
      }
      return number.toLocaleString('de-DE', { minimumFractionDigits: 0 });
    },

    getStats() {
      this.elasticTableNeedsRefresh = true;
      this.tableNeedsRefresh = true;
      this.$http
        .get('/api/statistics/overview')
        .then(res => {
          this.countByTable = [];
          this.countByTable = Object.keys(res.data.body)
            .sort()
            .filter(
              key =>
                ['program', 'module', 'chapter', 'workshop', 'goal', 'user', 'quiz'].indexOf(key)
                > -1,
            )
            .map(key => ({ key, value: res.data.body[key] }));
          this.tableNeedsRefresh = true;
        })
        .catch(err => console.warn(err));
      this.lastRefreshedAt = new Date();
    },
    titleCase(str) {
      str = str.toLowerCase().split(' ');
      for (let i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(' ');
    },
  },
};
</script>
<style lang="scss">
.contracts-table {
  .card-body {
    height: 343px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
</style>
