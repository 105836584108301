<template>
  <div class="content ml-2 manager-component container-fluid">
    <h2 class="mb-5">
      Gestion du questionnaire:
      <span class="text-primary">{{ currentQuiz && currentQuiz.title }}</span>
    </h2>
    <div class="row">
      <div class="col-md-3">
        <AwesomeForm
          v-bind="quizModelComputed"
          v-on="events"
          :useRouterMode="false"
          mode="view"
          displayMode="page"
          @edit="item => $router.push(`/app/quiz/${item.id}/edit`)"
          :item="quiz"
          @item-fetched="({ item }) => (currentQuiz = item)"
        />
      </div>
      <div class="col-md-9">
        <AwesomeCrud
          v-if="quizId"
          v-bind="quizQuestionModel"
          identity="quizQuestion"
          detailPageMode="modal"
          title="Questions"
          :apiRequestPermanentQueryParams="{ filters: { quizId: quizId } }"
          :apiRequestPermanentBodyParams="{ quizId: quizId }"
          v-on="events"
          :useRouterMode="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { AwesomeCrud, AwesomeForm } from 'vue-aw-components';
import _ from 'lodash';

export default {
  name: 'QuizManager',
  components: {
    AwesomeCrud,
    AwesomeForm,
  },
  props: {
    model: Object,
    events: Object,
    storePath: { type: String, default: 'state.model.models' },
  },
  data() {
    return {
      quizId: null,
      quiz: null,
      currentQuiz: null,
      quizQuestionModel: null,
    };
  },
  mounted() {
    this.quizId = this.$route.params.id;
    this.quiz = { id: this.$route.params.id };
    this.quizQuestionModel = this.getQuizQuestionModel();
    if (this.$awEventBus) {
      this.$awEventBus.$on('before-create', this.insertQuizQuestionId);
    }
  },
  beforeDestroy() {
    this.$awEventBus.$off('before-create', this.insertQuizQuestionId);
  },
  computed: {
    quizModelComputed() {
      return (
        this.model || _.get(this.$store, this.storePath).find(model => model.identity === 'quiz')
      );
    },
  },
  methods: {
    getQuiz() {},
    insertQuizQuestionId(data) {
      // eslint-disable-next-line
      const { identity, context, parent, parentIdentity } = data;
      if (identity === 'quizAnswer' && parent && parentIdentity === 'quizQuestion') {
        context.selectedItem.quizQuestionId = parent.id;
      }
    },
    getQuizQuestionModel() {
      const m = _.get(this.$store, this.storePath).find(model => model.identity === 'quizQuestion');
      const model = _.cloneDeep(m);
      model.options.detailPageMode = 'page';
      model.actions.search = false;
      model.actions.advancedFiltering = false;
      model.apiBodyParams = { quizId: this.quizId };
      model.nestedModels = [];
      const answerModel = _.cloneDeep(
        _.get(this.$store, this.storePath).find(md => md.identity === 'quizAnswer'),
      );
      model.nestedDisplayMode = 'table';
      answerModel.nestedDisplayMode = 'table';
      answerModel.url = ({ parent }) => `/api/quiz_answer?filters[quizQuestionId]=${parent.id}`;
      answerModel.url = ({ parent }) => `/api/quiz_answer?filters[quizQuestionId]=${parent.id}`;
      answerModel.actions.search = false;
      answerModel.actions.advancedFiltering = false;
      model.nestedModels.push({ ...answerModel });
      delete model.schema.properties.quizId;
      return model;
    },
  },
};
</script>
