<template>
  <div class="content ml-2 manager-component container-fluid">
    <h2 class="mb-5">
      Gestion du workshop:
      <span class="text-primary">{{ workshopTitle }}</span>
    </h2>
    <div class="row">
      <div class="col-md-3">
        <AwesomeForm
          v-if="workshopModelComputed && workshop"
          v-bind="workshopModelComputed"
          :useRouterMode="false"
          mode="view"
          displayMode="page"
          @edit="item => $router.push(`/app/workshop/${item.id}/edit`)"
          :item="workshop"
          @item-fetched="setWorkshop"
        />
      </div>
      <div class="col-md-9">
        <AwesomeCrud
          v-if="workshopId"
          v-bind="workshopSectionModel"
          identity="workshopSection"
          detailPageMode="modal"
          title="Sections du workshop"
          :apiRequestPermanentQueryParams="{
            filters: { workshopId: workshopId },
            options: { searchMode: 'exact' },
          }"
          :apiRequestPermanentBodyParams="{ workshopId: workshopId }"
          v-on="events"
          :useRouterMode="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { AwesomeCrud, AwesomeForm } from 'vue-aw-components';
import _ from 'lodash';

export default {
  name: 'WorkshopManager',
  components: {
    AwesomeCrud,
    AwesomeForm,
  },
  props: {
    model: Object,
    events: Object,
    storePath: { type: String, default: 'state.model.models' },
  },
  data() {
    return {
      workshopId: null,
      workshop: null,
      currentWorkshop: null,
      workshopSectionModel: null,
    };
  },
  mounted() {
    this.workshopId = this.$route.params.id;
    this.workshop = { id: this.$route.params.id };
    this.workshopSectionModel = this.getWorkshopSectionModel();

    if (this.$awEventBus) {
      this.$awEventBus.$on('before-create', this.insertWorkshopSectionId);
    }
  },
  beforeDestroy() {
    this.$awEventBus.$off('before-create', this.insertWorkshopSectionId);
  },
  computed: {
    workshopModelComputed() {
      return (
        this.model
        || _.get(this.$store, this.storePath).find(model => model.identity === 'workshop')
      );
    },

    workshopTitle() {
      return this.currentWorkshop && this.currentWorkshop.title;
    },
  },
  methods: {
    insertWorkshopSectionId(data) {
      // eslint-disable-next-line
      const { identity, context, parent, parentIdentity } = data;
      if (
        (identity === 'chapter' || identity === 'quiz')
        && parent
        && parentIdentity === 'workshopSection'
      ) {
        context.selectedItem.workshopSectionId = parent.id;
      }
    },
    getQuizz() {},
    getWorkshopSectionModel() {
      const m = _.get(this.$store, this.storePath).find(
        model => model.identity === 'workshopSection',
      );
      const model = _.cloneDeep(m);
      model.options.detailPageMode = 'page';
      model.actions.search = false;
      model.actions.advancedFiltering = false;
      model.apiBodyParams = { workshopId: this.workshopId };
      model.nestedModels = [];
      const chapterModel = _.cloneDeep(
        _.get(this.$store, this.storePath).find(md => md.identity === 'chapter'),
      );
      chapterModel.url = data => `/api/workshop-section/${data.parent.id}/chapter`;
      chapterModel.nestedDisplayMode = 'table';
      chapterModel.actions.search = false;
      chapterModel.actions.advancedFiltering = false;
      if (chapterModel) {
        model.nestedDisplayMode = 'table';
        model.nestedModels.push({ ...chapterModel });
      }

      const quizModel = _.cloneDeep(
        _.get(this.$store, this.storePath).find(md => md.identity === 'quiz'),
      );
      quizModel.url = data => `/api/quiz?filters[workshopSectionId]=${data.parent.id}`;
      quizModel.nestedDisplayMode = 'table';
      quizModel.actions.search = false;
      quizModel.actions.advancedFiltering = false;
      if (chapterModel) {
        model.nestedDisplayMode = 'table';
        model.nestedModels.push({ ...quizModel });
      }

      delete model.schema.properties.workshopId;
      return model;
    },
    setWorkshop({ item }) {
      this.currentWorkshop = item;
    },
  },
};
</script>

<style lang="scss"></style>
