<template>
  <AwesomeCrud v-bind="modelComputed" v-on="events" />
</template>

<script>
import { AwesomeCrud } from 'vue-aw-components';
import _ from 'lodash';

export default {
  name: 'CrudWrapper',
  components: {
    AwesomeCrud,
  },
  props: {
    identity: { type: String, required: true },
    model: Object,
    events: Object,
    storePath: { type: String, default: 'state.model.models' },
  },
  computed: {
    modelComputed() {
      return (
        this.model
        || _.get(this.$store, this.storePath).find(model => model.identity === this.identity)
      );
    },
  },
  methods: {},
};
</script>
